import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// helpers
// import { APICore } from '../helpers/api/apiCore';

// hooks
//import { useUser } from '../hooks';
import userList from '../helpers/user-list';
import regionalConfig from '../config_region';
import {
    getLoggedInToSisenseUser,
    LoggedInToSisenseUser,
} from '../helpers/api/getLoggedInToSisenseUser';
import Loader from '../components/Loader';

type PrivateRouteProps = {
    component: React.ComponentType;
    roles?: string;
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: RouteComponent, roles }: PrivateRouteProps) => {
    //let location = useLocation();
    //const [loggedInUser] = useUser();
    // TODO refactor all auth related stuff
    const loggedInUser = userList[0];

    //const api = new APICore();

    const [isLoading, setLoading] = useState(true);
    const [loggedInToSisenseUser, setLoggedInToSisenseUser] = useState<
        LoggedInToSisenseUser | null | Error
    >(null);

    useEffect(() => {
        async function getCurrentUser() {
            const response = await getLoggedInToSisenseUser();
            setLoggedInToSisenseUser(response);
            setLoading(false);
        }

        getCurrentUser();
    }, []);
    /**
     * not logged in so redirect to login page with the return url
     */
    // if (api.isUserAuthenticated() === false) {
    // 	return <Navigate to={'/auth/login'} state={{ from: location }} replace />;
    // }
    useEffect(() => {
        if (!isLoading && (!loggedInToSisenseUser || loggedInToSisenseUser instanceof Error)) {
            window.location.replace(regionalConfig.SISENSE_TRIAL_LOGIN_PAGE);
        }
    }, [isLoading, loggedInToSisenseUser]);

    if (isLoading) {
        return <Loader />;
    }

    // check if route is restricted by role
    if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/' }} />;
    }

    return <RouteComponent />;
};

export default PrivateRoute;
