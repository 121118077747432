import config from '../config';

export type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
    jobTitle: string;
    avatar: string;
};

const userList: UserData[] = [
    {
        id: 1,
        email: 'rihanna.jones@strategic-growth.com',
        username: 'rihanna.jones',
        password: 'winning',
        firstName: 'Rihanna',
        lastName: 'Jones',
        role: 'Admin',
        token: '',
        jobTitle: 'Chief Revenue Officer',
        avatar: 'user-11.jpg',
    },
    {
        id: 2,
        email: 'david.oconnor@strategic-growth.com',
        username: 'david.oconnor',
        password: 'winning',
        firstName: 'David',
        lastName: "O'Connor",
        role: 'Admin',
        token: '',
        jobTitle: 'Sales Manager',
        avatar: 'user-6.jpg',
    },
    {
        id: 3,
        email: 'cristina.sanchez@strategic-growth.com',
        username: 'cristina.sanchez',
        password: 'winning',
        firstName: 'Cristina',
        lastName: 'Sanchez',
        role: 'Admin',
        token: '',
        jobTitle: 'Sales Manager',
        avatar: 'user-9.jpg',
    },
    {
        id: 4,
        email: 'jerry.yang@strategic-growth.com',
        username: 'jerry.yang',
        password: 'winning',
        firstName: 'Jerry',
        lastName: 'Yang',
        role: 'Admin',
        token: '',
        jobTitle: 'Account Executive',
        avatar: 'user-7.jpg',
    },
];

if (config.DEPLOY_ENV == 'dev') {
    let adminUser: UserData = {
        id: 8,
        email: 'admin@sisensepoc.com',
        username: 'admin@sisensepoc.com',
        password: 'winning',
        firstName: 'Sisense',
        lastName: 'POC',
        role: 'Admin',
        token: '',
        jobTitle: 'Admin',
        avatar: 'morpheus.jpg',
    };
    userList.push(adminUser);
}

export default userList;
