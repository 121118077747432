import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
let store: any;

export function configureStore(initialState: {}) {
    const composeEnhancers =
        // eslint-disable-next-line no-undef
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const localstore = createStore(
        reducers,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    );
    sagaMiddleware.run(rootSaga);
    store = localstore;
    return localstore;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
