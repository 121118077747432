import regionalConfig from '../../config_region';

export type LoggedInToSisenseUser = {
    email: string;
    firstName: string;
    lastName: string;
};

export async function getLoggedInToSisenseUser(): Promise<LoggedInToSisenseUser | Error> {
    try {
        const response = await fetch(
            `${regionalConfig.TRIAL_APPS_SERVER_URL}/api/trial/current-user`,
            {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );
        const parsedBody = await response.json();
        if (parsedBody.error) {
            throw new Error(parsedBody.error);
        }
        return parsedBody;
    } catch (err: any) {
        return err as Error;
    }
}
